<template>
  <container>
    <div slot="bottom_box_content">
      <component :is="curBottomComponent" :inventoryId="detailId" @on-detail="showDetail"></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    listComponent: () => import('./components/List2.vue'),
    detailComponent: () => import('./components/Detail.vue')
  },
  data () {
    return {
      pageType: 'list',
      detailId: null
    }
  },
  mounted () {
    this.initTagEvent()
    this.initComponents()
  },
  destroyed () {
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  },
  methods: {
    initTagEvent () {
    // 注册头部事件
      this.setLinkRouterMaps(['mediaLibrayList', () => {
        this.detailId = 0
        this.pageType = 'list'
        this.initComponents()
      }])
    },
    initComponents () {
      // 清空便签项
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setLinkTagArray({ key: 'mediaLibrayList', value: '媒体商品清单' })

      switch (this.pageType) {
        case 'list':
          this.detailId = 0
          this.setBottomComponent('listComponent')
          this.setActivedTagName('mediaLibrayList')
          break
        case 'detail':
          this.setBottomComponent('detailComponent')
          this.setLinkTagArray({ key: 'mediaLibrayDetail', value: '商品详情' })
          this.setActivedTagName('mediaLibrayDetail')
          break
        default:
          break
      }
    },
    showDetail (id) {
      this.detailId = id
      this.pageType = 'detail'
      this.initComponents()
    }
  }
}
</script>
